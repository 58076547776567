<template>
  <div>
    <el-card>
      <all-log :dialogLog="dialogLog" />
      <div class="menu-box">
        <h2>拍摄点位管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加拍摄点位</el-button>
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="name" label="点位名称"> </el-table-column>
            <el-table-column prop="address_name" label="所属地址">
            </el-table-column>
            <el-table-column label="平面图">
              <template v-slot="scope">
                <img width="100" :src="scope.row.plan_url" alt="" />
              </template>
            </el-table-column>
            <el-table-column label="点位图">
              <template v-slot="scope">
                <img width="100" :src="scope.row.panorama_url" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item prop="name" label="点位名" :label-width="formLabelWidth">
          <el-input
            @change="handleChange"
            v-model="form.name"
            placeholder="请输入点位名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="address_id"
          label="地址"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="form.address_id"
            style="width: 100%"
            placeholder="请选择点位所属地址"
            @change="handleChangeAddress"
          >
            <el-option
              v-for="item in locList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          prop="plan_pic"
          label="现场平面图"
          :label-width="formLabelWidth"
        >
          <el-upload
            v-if="planFlag"
            accept=".jpg,.png,.jpeg"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            name="image"
            :file-list="planImgList"
            :on-change="getFile"
          >
            <el-button size="small" type="primary">上传现场平面图</el-button>
          </el-upload>
          <img v-else width="100" :src="form.plan_pic" alt="" />
        </el-form-item>
        <el-form-item
          prop="panorama_pic"
          label="点位实景图"
          :label-width="formLabelWidth"
        >
          <el-upload
            accept=".jpg,.png,.jpeg"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            name="image"
            :file-list="panaPicImgList"
            :on-change="getFile2"
          >
            <el-button size="small" type="primary">上传点位实景图</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" :disabled="forbid" @click="confirmAE()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getshootingList,
  addSpot,
  getInfo,
  editSpot,
  delSpot,
  ifPlan,
} from '@/api/edu/shootingspot.js'
import { getLocList } from '@/api/edu/location.js'
export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      planUrl: '',
      forbid: false,
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      planImgList: [],
      panaPicImgList: [],
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },
      optionProps: {
        checkStrictly: false,
        value: 'id',
        label: 'cname',
        children: 'sub',
      },
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        address_id: {
          required: true,
          message: '请选择地址',
          trigger: 'blur',
        },
        // sort: { required: true, message: '请输入物料列表排序' },
        name: { required: true, message: '请输入点位名称', trigger: 'blur' },
      },
      title: '添加服务地点',
      addOrEdit: 1,
      formLabelWidth: '120px',
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      planFlag: false,
      form: {
        name: '',
        address_id: '',
        plan_pic: '',
        panorama_pic: '',
      },
      tableData: [],
      locList: [],
      editPlan: false,
      editPana: false,
      editAddress: false,
    }
  },
  created() {
    this.getList()
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  methods: {
    exceed() {
      this.$message({
        message: '只能上传一张图片',
        type: 'warning',
      })
    },
    //文件转base64
    getBase64(file) {
      return new Promise(function(resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function() {
          imgResult = reader.result
        }
        reader.onerror = function(error) {
          reject(error)
        }
        reader.onloadend = function() {
          resolve(imgResult)
        }
      })
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isLt5M
    },

    getFile(file, fileList) {
      //  console.log('plan change')
      this.isEdit = true
      this.editPlan = true
      if (this.beforeAvatarUpload(file)) {
        this.getBase64(file.raw).then((res) => {
          //     console.log(res)
          this.form.plan_pic = res
          //   console.log(this.form.plan_pic)

          // this.ruleForm.imagePath = res
        })
      }
    },
    getFile2(file, fileList) {
      this.isEdit = true
      this.editPana = true
      if (this.beforeAvatarUpload(file)) {
        this.getBase64(file.raw).then((res) => {
          this.form.panorama_pic = res

          // this.ruleForm.imagePath = res
        })
      }
    },
    async getOptionList() {
      const { data: res } = await getLocList()
      console.log(res)
      if (res.code === 1) {
        this.locList = res.data
      } else {
        this.$message.error('获取具体点位数据失败！')
      }
    },

    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await getshootingList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data
        this.exp.total = res.exp.num
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    async handleChangeAddress() {
      this.isEdit = true
      this.editAddress = true
      const { data: res } = await ifPlan({ address_id: this.form.address_id })
      console.log(res)
      if (res.code === 1) {
        this.planUrl = res.data.have
        this.planFlag = res.data.have === '' ? true : false
        this.form.plan_pic = this.planUrl
      }
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加拍摄点位'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        name: '',
        address_id: '',
        plan_pic: '',
        panorama_pic: '',
      }
      this.planImgList = []
      this.panaPicImgList = []
      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)
      this.getOptionList()
      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      this.dialogVisible = true
      this.title = '编辑拍摄点位'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        photo_place_id: row.id,
      })
      console.log(res.data)
      this.getOptionList()
      this.planFlag = true
      this.form.name = res.data.name
      this.form.photo_place_id = res.data.id
      this.form.address_id = res.data.address_id
      this.form.status = res.data.status
      this.form.panorama_url = res.data.panorama_url
      this.form.plan_url = res.data.plan_url

      this.planImgList.push({ url: res.data.plan_url })
      this.panaPicImgList.push({ url: res.data.panorama_url })
    },

    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delSpot({
            photo_place_id: row.id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            this.forbid = true

            if (this.planFlag === false) {
              // 没有平面图
              this.form.plan_pic = this.planUrl
            }
            const { data: res } = await addSpot(this.form)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
            this.forbid = false
          } else {
            // 编辑
            let plan_pic = ''
            let panorama_pic = ''
            if (this.isEdit === true) {
              if (!this.editAddress && this.editPlan && this.editPana) {
                plan_pic = this.form.plan_pic
                panorama_pic = this.form.panorama_pic
              } else if (!this.editAddress && !this.editPlan && this.editPana) {
                console.log('改了实景图没改别的')
                plan_pic = this.form.plan_url
                panorama_pic = this.form.panorama_pic
              } else if (!this.editAddress && this.editPlan && !this.editPana) {
                plan_pic = this.form.plan_pic
                panorama_pic = this.form.panorama_url
              } else if (
                !this.editAddress &&
                !this.editPlan &&
                !this.editPana
              ) {
                plan_pic = this.form.plan_url
                panorama_pic = this.form.panorama_url
              } else if (this.editAddress && !this.editPana) {
                console.log('改了地址没改别的')
                plan_pic = this.form.plan_pic
                panorama_pic = this.form.panorama_url
              } else if (this.editAddress && this.editPana) {
                console.log('改了地址改了实景图')
                plan_pic = this.form.plan_pic
                panorama_pic = this.form.panorama_pic
              }
              const { data: res } = await editSpot({
                photo_place_id: this.form.photo_place_id,
                plan_pic,
                panorama_pic,
                address_id: this.form.address_id,
                name: this.form.name,
                status: this.form.status,
              })
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
