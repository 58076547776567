import { post } from '@/utils/request'

//获取目录
export function getshootingList(data) {
    return post('/edu/photo_place/photoPlaceList', data)
}

//添加拍摄点
export function addSpot(data) {
    return post('/edu/photo_place/addPhotoPlace', data)
}
//服务地址回显
export function getInfo(data) {
    return post('/edu/photo_place/photoPlaceInfo', data)
}

//拍摄点位编辑
export function editSpot(data) {
    return post('/edu/photo_place/editPhotoPlace', data)
}
//拍摄点位删除
export function delSpot(data) {
    return post('/edu/photo_place/delPhotoPlace', data)
}
// 根据地址获取是否有平面图
export function ifPlan(data) {
    return post('/edu/photo_place/photo', data)
}


